import apiRequest from "./apiRequest";

const vaccineService = {
    getAllVacinesPersonTypes: async () => {
        console.log("Getting all vaccine person type ============")
        try {
            const res = await apiRequest({ url: `/vaccine-person-type-list` })
            const resData = res.data;

            if (resData.status === "OK") {
                return resData.data
            } else {
                return []
            }
        } catch (e) {
            throw new Error("Error getting vaccine person types: ", e)
        }
    },
    getAllVaccineType: async () => {
        console.log("Getting all vaccine type ===========")
        try {
            const res = await apiRequest({ url: `/vaccine-list` })
            const resData = res.data;

            if (resData.status === "OK") {
                return resData.data
            } else {
                return []
            }
        } catch (e) {
            throw new Error("Error getting vaccine types: ", e)
        }
    },


}

export default vaccineService;