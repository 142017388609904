import apiRequest from "./apiRequest";

const patientService = {
    getAll: async (data) => {
        try {
            const phoneToString = data.toString();
            const res = await apiRequest({
                url: `/phoneExist/${phoneToString}`,
                requestMethod: "POST",
                requestBody: {}
            })
            const resData = res.data;

            if (resData.status === "OK") {
                return {
                    user: resData.data.client ?? null,
                    patients: resData.data.children ?? []
                }
            } else {
                return {
                    user: null,
                    patients: []
                }
            }

        } catch (e) {
            throw Error("Error getting all patients data")
        }
    },
    getOneById: async (data) => {
        try {
            const res = await apiRequest({
                url: `/child-read/${data}`,
            })
            const resData = res.data;

            if (resData.status === "OK") {
                return resData.data
            } else {
                return null
            }
        } catch (e) {
            throw Error("Error getting patient data")
        }
    },


}

export default patientService;