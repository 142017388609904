<script>
export default {
  name: "HomePage",
  data() {
    return {
      nextStepLink: "",
      newRecordLink: "",
    };
  },
  created() {
    const phone = this.$route.query.phone;
    this.nextStepLink = `/patient-list/${phone}`;
    this.newRecordLink = `/register?phone=${phone}`;
  },
};
</script>

<template>
  <div class="flex flex-grow flex-col px-4 pt-8 text-center text-white">
    <div class="flex flex-col gap-y-3">
      <RouterLink
        :to="nextStepLink"
        class="flex w-full items-center justify-center rounded bg-green-700 p-4"
      >
        <span>Suivi</span>
      </RouterLink>
      <RouterLink
        class="flex w-full items-center justify-center rounded bg-blue-700 p-4"
        :to="newRecordLink"
      >
        <span>Nouvel enregistrement</span>
      </RouterLink>
    </div>
  </div>
</template>
