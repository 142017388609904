<script>
export default {
  name: "SuccessPage",
  data() {
    return {
      nextStepLink: "",
    };
  },
  created() {
    const phone = this.$route.query.phone;
    this.nextStepLink = `/home?phone=${phone}`;
  },
};
</script>

<template>
  <div class="flex flex-grow flex-col items-center justify-center px-8 py-16">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style="color: #10b981"
      class="h-16 w-16"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="1"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
    <p class="text-md mx-auto text-center text-gray-500">
      Merci pour votre enregistrement !
    </p>
    <div class="mb-4 flex p-6 text-center text-white">
      <RouterLink :to="nextStepLink" class="w-full rounded bg-green-700 p-2">
        Retour a l'accueil
      </RouterLink>
    </div>
  </div>
</template>
