<script>
import PatientFormComponent from "@/components/PatientFormComponent.vue";
import ErrorHandlerComponent from "@/components/ErrorHandlerComponent.vue";

export default {
  components: { PatientFormComponent, ErrorHandlerComponent },
  name: "RegisterPage",
  data() {
    return {
      isPatientDataLoading: false,
      isSomethingWentWrong: false,
      patientData: {
        phone: "",
      },
    };
  },
  methods: {
    getPhoneNumberFromURL() {
      try {
        this.isPatientDataLoading = true;
        this.patientData.phone = this.$route.query.phone ?? "";
      } catch (error) {
        console.log("Something went wrong");
      } finally {
        this.isPatientDataLoading = false;
      }
    },

    async init() {
      this.isPatientDataLoading = true;
      try {
        this.getPhoneNumberFromURL();
      } catch (error) {
        this.isSomethingWentWrong = true;
      } finally {
        this.isPatientDataLoading = false;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<template>
  <template v-if="isPatientDataLoading">
    <ContentSectionLoading />
  </template>
  <template v-else>
    <template v-if="isSomethingWentWrong">
      <error-handler-component :submit-handler="getPhoneNumberFromURL" />
    </template>
    <template v-else>
      <PatientFormComponent :update-mode="false" :patient="patientData" />
    </template>
  </template>
</template>
