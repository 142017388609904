import apiRequest from "./apiRequest";

const locationService = {
    getAllTowns: async () => {
        console.log("Getting all towns  ===========");
        try {
            const res = await apiRequest({ url: `/town-list` })
            const resData = res.data;

            if (resData.status === "OK") {
                return resData.data
            } else {
                return []
            }
        } catch (e) {
            throw new Error("Error getting towns: ", e)
        }

    },
    getAllDistricts: async () => {
        console.log("Getting all districts ===========");
        try {
            const res = await apiRequest({ url: `/district-list` })
            const resData = res.data;

            if (resData.status === "OK") {
                return resData.data
            } else {
                return []
            }
        } catch (e) {
            throw new Error("Error getting districts: ", e)
        }

    },
    getAllHealthcenter: async () => {
        console.log("Getting all health centers ===========")
        try {
            const res = await apiRequest({ url: `/health-center-list` })
            const resData = res.data;

            if (resData.status === "OK") {
                return resData.data
            } else {
                return []
            }
        } catch (e) {
            throw new Error("Error getting health centers: ", e)
        }
    },

}

export default locationService;