export const phoneNumberPrefixRemoval = (phoneNumber) => {

    const prefixes = ["00237", "+00237", "+237", "237", "0237"];

    for (const prefix of prefixes) {
        if (String(phoneNumber).startsWith(prefix)) {
            const phoneNumberRefactor = phoneNumber.slice(prefix.length);
            return phoneNumberRefactor
        }
    }
    return phoneNumber;


};