<script>
export default {
  name: "ContentSectionLoading",
};
</script>

<template>
  <div class="mt-auto flex min-h-[80dvh] items-center justify-center">
    <LoaderComponent />
  </div>
</template>
