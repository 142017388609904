import { createWebHistory, createRouter } from 'vue-router'
import LoginPage from './pages/login/LoginPage.vue';
import RegisterPage from './pages/register/RegisterPage.vue';
import HomePage from './pages/home/HomePage.vue';
import SuccessPage from './pages/success/SuccessPage.vue';
import UpdatePage from './pages/update/UpdatePage.vue';
import PatientListPage from './pages/patient-list/PatientListPage.vue';


const routes = [
    { path: '/', name: 'login', component: LoginPage },
    { path: '/register', name: 'register', component: RegisterPage },
    { path: '/home', name: 'home', component: HomePage },
    { path: '/patient-list/:phone', name: 'patients', component: PatientListPage },
    { path: '/patient-update/:id', name: 'update', component: UpdatePage },
    { path: '/success', name: "success", component: SuccessPage },

    // {
    //     path: '/next-step',
    //     name: "next-step",
    //     children: [
    //         { path: 'vacine-person-type', name: "vacinePersonType", component: VacinePersonTypePage },
    //         { path: 'patient-name', name: "patientName", component: PatientNamePage },
    //         { path: 'town-select', name: "townSelect", component: TownSelectPage },
    //         { path: 'district-select', name: "districtSelect", component: DistricSelectPage },
    //         { path: 'medecine-select', name: "medecineSelect", component: MedicineSelectPage },
    //         { path: 'validate-process', name: "validateProcess", component: ValidateProcessPage },

    //     ],
    // },
    // {
    //     path: '/status',
    //     children: [
    //         { path: 'success', name: "statusSucess", component: StatusSucessPage },
    //     ],
    // },
]

const routeConfig = createRouter({
    history: createWebHistory(),
    routes,
})

export default routeConfig;