/* eslint-disable no-unused-vars */
import apiRequest from "./apiRequest";

const authService = {
    login: async (data) => {
        try {
            const res = await apiRequest({
                url: `/phoneExist/${data}`,
                requestMethod: "POST",
                requestBody: {}
            })
            const resData = res.data;

            if (resData.status === "OK") {
                return true
            } else {
                return false
            }
        } catch (e) {
            throw Error("Something went wrong while sending request to check phone")
        }
    },
    register: async (data) => {
        try {
            const phoneToString = data.phone.toString();
            const res = await apiRequest({
                url: `/client-create`,
                requestMethod: "POST",
                requestBody: data
            });
            const resData = res.data;
            console.log(resData)
            if (resData.status === "OK") {
                return true
            } else {
                return false
            }
        } catch (error) {
            throw new Error("Error registering user");
        }
    },
    update: async (data) => {
        try {
            const phoneToString = data.phone.toString();
            const res = await apiRequest({
                url: `/child-update`,
                requestMethod: "POST",
                requestBody: data
            });
            const resData = res.data;
            if (resData.status === "OK") {
                return true
            } else {
                return false
            }
        } catch (error) {
            throw new Error("Error updating user");
        }
    }
}


export default authService