<script>
import StateStorage from "../../utils/StateStorage";
// import apiRequest from "../../services/apiRequest";
import authService from "../../services/authService";
import LoaderComponent from "@/components/LoaderComponent.vue";

export default {
  components: { LoaderComponent },
  name: "LoginPage",
  data() {
    return {
      isLoginLoading: false,
      isLoginError: false,
      phone: "",
    };
  },
  methods: {
    async loginHandler() {
      if (this.phone !== "") {
        try {
          this.isLoginLoading = true;
          const phoneToString = this.phone.toString();

          const phoneExist = await authService.login(phoneToString);

          if (phoneExist) {
            StateStorage.set("phone", phoneToString);
            this.$router.push(`/home?phone=${this.phone}`);
          } else {
            this.$router.push(`/register?phone=${this.phone}`);
          }
        } catch (error) {
          this.isLoginError = true;
          console.log("Something went wrong,", error);
        } finally {
          this.isLoginLoading = false;
        }
      } else {
        alert("veuillez entrer votre numéro de téléphone");
      }
    },
  },
  mounted() {},
};
</script>

<template>
  <div class="flex h-full flex-grow justify-center px-8 pt-8">
    <template v-if="isLoginLoading"> <loader-component /> </template>
    <template v-else>
      <template v-if="isLoginError">
        <error-handler-component :submit-handler="loginHandler" />
      </template>
      <template v-else>
        <div class="flex w-full flex-col rounded text-center">
          <!-- <label>Entrer votre numéro de téléphone</label> -->
          <input
            id="phone"
            placeholder="Saisissez votre numéro de téléphone"
            v-model="phone"
            type="number"
            class="rounded-md bg-slate-50 p-4 text-center shadow-md"
          />
          <br />
          <div
            class="mb-4 flex items-center justify-center text-center text-white"
          >
            <button
              v-if="!isLoginLoading"
              class="text-md w-full rounded bg-green-700 p-4 font-semibold"
              @click="loginHandler()"
            >
              Se connecter
            </button>
            <LoaderComponent v-else />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>
