<script>
import PatientComponent from "@/components/PatientComponent.vue";
import patientService from "@/services/patientService";
import ErrorHandlerComponent from "@/components/ErrorHandlerComponent.vue";

export default {
  components: { PatientComponent, ErrorHandlerComponent },
  name: "PatientListPage",
  data() {
    return {
      patientListData: [],
      userData: [],
      phoneNumber: "",
      isPatientListLoading: false,
      isPatientListError: false,
    };
  },
  methods: {
    async getAllPatientsRelatedToPhone(phoneNumber) {
      //   console.log("Getting user data with phone: ", phoneNumber);

      try {
        this.isPatientListLoading = true;
        const resData = await patientService.getAll(phoneNumber);
        return resData;
      } catch (error) {
        this.isPatientListError = true;
      } finally {
        this.isPatientListLoading = false;
      }
    },
    async initiatePatientsData() {
      const phone = this.$route.params.phone;
      this.phoneNumber = phone;
      const resData = await this.getAllPatientsRelatedToPhone(phone);
      this.patientListData = resData.patients;
      this.userData = resData.user;
    },
  },

  async mounted() {
    this.initiatePatientsData();
  },
};
</script>

<template>
  <template v-if="isPatientListLoading">
    <div class="flex flex-grow flex-col items-center justify-center px-4">
      <LoaderComponent />
    </div>
  </template>
  <template v-else>
    <template v-if="isPatientListError">
      <error-handler-component :submit-handler="initiatePatientsData" />
    </template>
    <template v-else>
      <template v-if="patientListData.length === 0">
        <div class="flex flex-grow flex-col items-center justify-center px-4">
          <span>No children found, to register one, </span>
          <span class="ml-2 text-blue-400">
            <RouterLink :to="`/register?phone=${phoneNumber}`"
              >click here</RouterLink
            >
          </span>
        </div>
      </template>
      <template v-else>
        <div class="h-[600px] rounded-sm">
          <div class="py-3 text-center text-xl font-semibold">
            List des patients
          </div>
          <div
            class="flex h-full flex-grow flex-col gap-y-4 overflow-y-scroll p-4"
          >
            <template
              v-for="(patientData, index) in patientListData"
              :key="patientData.id"
            >
              <patient-component :no="index + 1" :patient="patientData" />
            </template>
          </div>
        </div>
      </template>
    </template>
  </template>
</template>
