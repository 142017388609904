<script>
export default {
  name: "PatientComponent",
  props: ["patient", "no"],
  data() {
    return {
      generatedURL: "",
    };
  },
  methods: {
    generateURLUsingPatientData() {
      const newUpdateURl = `/patient-update/${this.patient.id}?patient_name=${this.patient.name}&birth=${this.patient.birth}&register_name=${this.patient.register_name}&phone=${this.patient.phone}&other_phone=${this.patient.other_phone}&vaccine_person_type_id=${this.patient.vaccine_id}&prev_vaccine_type_id=${this.patient.vaccine_type_id}`;
      return newUpdateURl;
    },
    redirectToUpdatePage() {
      const urlToUpdate = this.generateURLUsingPatientData();
      this.$router.push(urlToUpdate);
    },
  },
};
</script>

<template>
  <div
    :onclick="redirectToUpdatePage"
    class="flex cursor-pointer items-center justify-between rounded-md border bg-slate-50 p-4 hover:bg-slate-100"
  >
    <div>{{ no }} - {{ patient.name }}</div>
    <div class="rounded-md bg-blue-400 p-2 text-white">Consulter</div>
  </div>
</template>
