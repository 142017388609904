<script>
import StateStorage from "@/utils/StateStorage";
import { phoneNumberPrefixRemoval } from "@/utils/Helpers";
import locationService from "@/services/locationService";
import vaccineService from "@/services/vacineService";
import authService from "@/services/authService";

// In case of a range picker, you'll receive [Date, Date]

export default {
  name: "PatientFormComponent",
  props: ["updateMode", "patient", "patientId", "vaccineTypesByPatient"],
  data() {
    return {
      townsDataFromService: [],
      districtsDataFromService: [],
      healthCentersDataFromService: [],
      vaccinePersonTypesDataFromService: [],
      vaccineTypesDataFromService: [],

      townsData: [],
      districtsData: [],
      healthCentersData: [],
      vaccinePersonTypesData: [],
      vaccineTypesData: [],

      form: {
        name: "",
        phone: "",
        other_phone: "",
        town: {
          id: "",
          name: "",
        },
        patient_name: "",
        patient_dob: "",
        health_center: {
          id: "",
          name: "",
        },
        district: {
          id: "",
          name: "",
        },
        vaccine_person_type: {
          id: "",
          name: "",
        },
        vaccine_type: {
          id: "",
          name: "",
        },
        next_visite_date: "",
      },
      isFormInValid: false,
      isFormLoading: false,
      isFormError: false,

      isServiceInitialDataLoading: false,
      isServiceInitialDataError: false,
    };
  },
  methods: {
    format(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
    async getPhoneFromStorage() {
      const phoneNumber = StateStorage.get("phone");
      if (!phoneNumber) {
        this.$router.push("/login");
      } else {
        this.form.phone = phoneNumberPrefixRemoval(phoneNumber);
      }
    },
    // getUpdatedVaccineTypesList(vaccineId, vaccineTypesArray) {
    //   const startIndex = vaccineTypesArray.findIndex(
    //     (vaccineType) => vaccineType.id === vaccineId
    //   );

    //   // If the vaccine_id is not found or it's the last one, return an empty array
    //   if (startIndex === -1 || startIndex === vaccineTypesArray.length - 1) {
    //     return [];
    //   }

    //   // Return the sublist starting from the next index to the end
    //   return vaccineTypesArray.slice(startIndex + 1);
    // },

    async getDistrictsByTownId(townId) {
      const newDistrictsData = this.districtsDataFromService.filter(
        (districtData) => {
          return districtData.town_id === townId;
        }
      );
      return newDistrictsData ? newDistrictsData : [];
    },
    async getHealthCenterByDistrictId(districtId) {
      const newHealthCenterData =
        await this.healthCentersDataFromService.filter((healthCenter) => {
          return healthCenter.district_id === districtId;
        });
      //   this.healthCentersData = newHealthCenterData;
      return newHealthCenterData ? newHealthCenterData : [];
    },
    // eslint-disable-next-line no-unused-vars
    async getAllVaccineTypesByVacinePersonTypeId(vaccinePersonTypeId) {
      const newVacineTypesData = await this.vaccineTypesDataFromService.filter(
        (vaccineTypeData) => {
          return vaccineTypeData.vaccine_id === vaccinePersonTypeId;
        }
      );
      //   this.vaccineTypesData = newVacineTypesData;
      return newVacineTypesData ? newVacineTypesData : [];
    },
    async onTownChange() {
      this.form.district.id = "";
      this.form.health_center.id = "";
      const newDistrictsData = await this.getDistrictsByTownId(
        this.form.town.id
      );
      //   console.log("Districts:", this.districtsDataFromService);
      //   console.log("New districts: ", newDistrictsData);

      return (this.districtsData = newDistrictsData);
    },
    async onDiscrictChange() {
      this.form.health_center.id = "";

      const newHealthCentersData = await this.getHealthCenterByDistrictId(
        this.form.district.id
      );
      console.log("New health centers: ", newHealthCentersData);
      return (this.healthCentersData = newHealthCentersData);
    },
    async onChangeVaccinePersonType() {
      this.form.vaccine_type.id = "";

      const newVaccineTypes = await this.getAllVaccineTypesByVacinePersonTypeId(
        this.form.vaccine_person_type.id
      );
      //   console.log("New vaccine types: ", newVaccineTypes);

      return (this.vaccineTypesData = newVaccineTypes);
    },

    async init() {
      const formDataGotten = this.patient;
      console.log("Form data gotten", formDataGotten);
      const removePhoneNumberPrefix = phoneNumberPrefixRemoval(
        formDataGotten.phone
      );
      const newFormDataObj = {
        ...formDataGotten,
        phone: removePhoneNumberPrefix,
      };

      this.form = {
        ...this.form,
        ...newFormDataObj,
      };

      // if (this.updateMode) {
      //   this.form.vaccine_person_type =
      //     await this.vaccinePersonTypesDataFromService.find(
      //       (vaccinePersonTypeData) => {
      //         return (vaccinePersonTypeData.id =
      //           this.newFormDataObj.vaccine_person_type.id);
      //       }
      //     );
      //   const newVaccinesTypesList =
      //     await this.getAllVaccineTypesByVacinePersonTypeId(
      //       newFormDataObj.vaccine_person_type.id
      //     );

      //   const newVaccineTypeListUpdatedFromPrevious =
      //     await this.getUpdatedVaccineTypesList(
      //       newFormDataObj.vaccine_type.id,
      //       newVaccinesTypesList
      //     );

      //   this.vaccineTypesData = newVaccineTypeListUpdatedFromPrevious;
      // }
    },

    setFormErrorToFalse() {
      this.isFormError = false;
    },
    async formHandler() {
      if (
        this.form.phone !== "" &&
        // this.form.other_phone !== "" &&
        this.form.town !== "" &&
        // this.form.name !== "" &&
        this.form.health_center !== "" &&
        this.form.district.id !== "" &&
        this.form.vaccine_type.id !== "" &&
        this.form.next_visite_date !== ""
      ) {
        if (this.updateMode === true) {
          console.log("Data will be update");
          const payload = {
            name: this.form.name,
            patient_name: this.form.patient_name,
            health_center: this.form.health_center.id,
            town: this.form.town.id,
            vaccine_type: this.form.vaccine_type.id,
            district: this.form.district.id,
            date_of_birth: this.form.patient_dob,
            next_date: this.form.next_visite_date,
            phone: this.form.phone,
            other_phone: this.form.other_phone,
            child_id: this.patientId,
          };
          //   console.log(payload);

          try {
            this.isFormLoading = true;
            const isDataRegistered = await authService.update(payload);
            if (isDataRegistered) {
              console.log("updated");
              this.$router.push(`/success?phone=${this.form.phone}`);
            }
          } catch (error) {
            this.isFormError = true;
          } finally {
            this.isFormLoading = false;
          }
          return;
        } else {
          // Registration Logic
          const payload = {
            name: this.form.name,
            patient_name: this.form.patient_name,
            vaccine: this.form.vaccine_person_type.id,
            health_center: this.form.health_center.id,
            town: this.form.town.id,
            vaccine_type: this.form.vaccine_type.id,
            district: this.form.district.id,
            date_of_birth: this.form.patient_dob,
            next_date: this.form.next_visite_date,
            phone: this.form.phone,
            other_phone: this.form.other_phone,
          };

          try {
            this.isFormLoading = true;
            const isDataRegistered = await authService.register(payload);
            if (isDataRegistered) {
              //   console.log("created");
              this.$router.push(`/success?phone=${this.form.phone}`);
            }
          } catch (error) {
            this.isFormError = true;
          } finally {
            this.isFormLoading = false;
          }
        }
      } else {
        // console.log("Some validation are blocking");
        this.isFormInValid = true;
        return;
      }
    },

    async getServiceInitialData() {
      try {
        this.isServiceInitialDataLoading = true;

        this.townsDataFromService = await locationService.getAllTowns();
        this.districtsDataFromService = await locationService.getAllDistricts();
        this.healthCentersDataFromService =
          await locationService.getAllHealthcenter();
        if (this.updateMode === false) {
          this.vaccinePersonTypesDataFromService =
            await vaccineService.getAllVacinesPersonTypes();
          this.vaccineTypesDataFromService =
            await vaccineService.getAllVaccineType();
        } else {
          //   console.log("Vaccine person type: ", this.form.vaccine_person_type);
          //   console.log("Vaccine list gotten: ", this.vaccineTypesByPatient);
          this.vaccineTypesDataFromService = this.vaccineTypesByPatient;
          this.vaccinePersonTypesDataFromService = [
            this.form.vaccine_person_type,
          ];
        }

        await this.init();
      } catch (error) {
        this.isServiceInitialDataError = true;
      } finally {
        this.isServiceInitialDataLoading = false;
      }
    },
  },
  computed: {
    buttonText() {
      return this.updateMode ? "Valide" : "Enregistrer";
    },
    buttonClass() {
      return this.updateMode ? "bg-green-500" : "bg-[#0a52f2]";
    },
  },
  async mounted() {
    await this.getServiceInitialData().then(() => {
      //   console.log("============= Adding default default data");
      this.vaccinePersonTypesData = this.vaccinePersonTypesDataFromService;
      this.townsData = this.townsDataFromService;
      if (this.updateMode) {
        this.vaccineTypesData = this.vaccineTypesDataFromService;
      }
    });
  },
  created() {},
};
</script>

<template>
  <template v-if="isServiceInitialDataLoading">
    <ContentSectionLoading />
  </template>
  <template v-else>
    <template v-if="isServiceInitialDataError">
      <error-handler-component :submit-handler="getServiceInitialData" />
    </template>
    <template v-else>
      <template v-if="isFormError">
        <error-handler-component :submit-handler="setFormErrorToFalse" />
      </template>
      <template v-else>
        <div
          v-if="isFormInValid"
          class="flex space-x-1 bg-red-200 p-3 text-sm text-red-600"
        >
          <span><v-icon name="io-alert-circle-sharp"></v-icon></span>
          <span class="block"
            >Veuillez remplir tous les champs obligatoires</span
          >
        </div>
        <form
          class="my-3 mb-4"
          @submit.prevent="formHandler"
          autocomplete="off"
        >
          <!-- your name -->
          <template v-if="!updateMode">
            <SectionComponent>
              <label
                class="float-left mb-2 block text-sm text-gray-700"
                for="name"
              >
                Entrez votre nom <span class="text-red-500">*</span>
              </label>
              <input
                v-model="form.name"
                class="focus:shadow-outline w-full appearance-none rounded border px-2 py-1 leading-tight focus:outline-none"
                style="background-color: #e5e7eb"
                id="name"
                type="text"
                required
              />
              <span class="text-[0.775rem] text-red-500" v-if="form.name === ''"
                >veuillez renseigner votre nom</span
              >
            </SectionComponent>
          </template>

          <!-- patient name -->
          <SectionComponent>
            <label
              class="float-left mb-2 block text-sm text-gray-700"
              for="patient_name"
            >
              Entrez le nom du patient
              <span v-if="!updateMode" class="text-red-500">*</span>
            </label>
            <input
              v-model="form.patient_name"
              class="focus:shadow-outline w-full appearance-none rounded border px-2 py-1 leading-tight focus:outline-none"
              style="background-color: #e5e7eb"
              id="patient_name"
              type="text"
              required
            />
            <span
              class="text-[0.775rem] text-red-500"
              v-if="form.patient_name === ''"
              >veuillez renseigner le nom de votre enfant</span
            >
          </SectionComponent>

          <!-- Date of birth -->
          <SectionComponent>
            <label
              class="float-left mb-2 block text-sm text-gray-700"
              for="patient_name"
            >
              Entrez la date de naissance patient
              <span class="text-red-500">*</span>
            </label>
            <VueDatePicker
              v-model="form.patient_dob"
              model-type="dd/MM/yyyy"
              placeholder="Cliquez pour choisir la date"
              :format="format"
              required
              :enable-time-picker="false"
            ></VueDatePicker>
            <!-- <input
              v-model="form.patient_dob"
              class="focus:shadow-outline w-full appearance-none rounded border px-2 py-1 leading-tight focus:outline-none"
              style="background-color: #e5e7eb"
              id="patient_name"
              type="date"
              required
            /> -->
            <span
              class="text-[0.775rem] text-red-500"
              v-if="form.patient_dob === ''"
              >veuillez renseigner le nom de votre enfant</span
            >
          </SectionComponent>

          <!-- phone -->
          <SectionComponent>
            <label
              class="float-left mb-2 block text-sm text-gray-700"
              for="phone"
            >
              Entrez votre numéro de téléphone
              <span class="text-red-500">*</span>
            </label>
            <div class="flex w-full items-center">
              <span class="bg-green-200 px-2 py-1 text-sm">+237</span>
              <input
                v-model="form.phone"
                class="focus:shadow-outline w-full appearance-none px-2 py-1 leading-tight focus:outline-none"
                style="background-color: #e5e7eb"
                id="phone"
                minlength="9"
                type="number"
                required
              />
            </div>
            <span class="text-[0.775rem] text-red-500" v-if="form.phone === ''"
              >veuillez renseigner votre numéro de téléphone</span
            >
          </SectionComponent>

          <!--  2nd parent number -->
          <SectionComponent>
            <label
              class="float-left mb-2 block text-sm text-gray-700"
              for="other_phone"
            >
              Entrez votre dexieme numéro de téléphone
            </label>
            <div class="flex w-full items-center">
              <span class="bg-green-200 px-2 py-1 text-sm">+237</span>
              <input
                v-model="form.other_phone"
                class="focus:shadow-outline w-full appearance-none px-2 py-1 leading-tight focus:outline-none"
                style="background-color: #e5e7eb"
                id="other_phone"
                minlength="9"
                type="number"
              />
            </div>
            <span class="text-[0.775rem] text-red-500" v-if="form.phone === ''"
              >veuillez renseigner votre numéro de téléphone</span
            >
          </SectionComponent>

          <!-- town -->
          <SectionComponent>
            <label class="mb-2 text-sm text-gray-700" for="town">
              Entrez votre ville <span class="text-red-500">*</span>
            </label>
            <select
              v-model="form.town.id"
              id="town"
              @change="onTownChange"
              class="mt-2 w-full rounded border-2 border-gray-200 px-2 py-1 text-gray-700"
              style="background-color: #e5e7eb"
            >
              <option disabled selected :value="form.town.id">
                Choisissez votre ville
              </option>
              <template v-if="townsData.length !== 0">
                <option
                  v-for="town in townsData"
                  :key="town.id"
                  :value="town.id"
                >
                  {{ town.name }}
                </option>
              </template>
              <template v-else>
                <option disabled value="">No towns found</option>
              </template>
            </select>
            <template v-if="form.town.id === ''">
              <span class="text-[0.775rem] text-red-500"
                >veuillez renseigner votre ville</span
              >
            </template>
          </SectionComponent>

          <!-- district -->
          <template v-if="form.town.id !== ''">
            <SectionComponent>
              <label class="mb-2 text-sm text-gray-700" for="district">
                Entrez votre district <span class="text-red-500">*</span>
              </label>
              <select
                v-model="form.district.id"
                id="district"
                @change="onDiscrictChange"
                class="mt-2 w-full rounded border-2 border-gray-200 px-2 py-1 text-gray-700"
                style="background-color: #e5e7eb"
              >
                <option disabled selected :value="form.district.id">
                  Choisissez votre district
                </option>
                <template v-if="districtsData.length !== 0">
                  <option
                    v-for="districtData in districtsData"
                    :key="districtData.id"
                    :value="districtData.id"
                  >
                    {{ districtData.name }}
                  </option>
                </template>
                <template v-else>
                  <option disabled value="">No districts found</option>
                </template>
              </select>
              <span
                class="text-[0.775rem] text-red-500"
                v-if="form.district.id === ''"
                >veuillez renseigner votre ville</span
              >
            </SectionComponent>
          </template>

          <!-- health_center -->
          <template v-if="form.district.id !== ''">
            <SectionComponent>
              <label class="mb-2 text-sm text-gray-700" for="healthCenter">
                Entrez votre centre de santé
                <span class="text-red-500">*</span>
              </label>
              <select
                v-model="form.health_center.id"
                id="healthCenter"
                class="mt-2 w-full rounded border-2 border-gray-200 px-2 py-1 text-gray-700"
                style="background-color: #e5e7eb"
              >
                <option disabled selected :value="form.health_center.id">
                  Choisissez votre centre de sante
                  <span class="text-red-500">*</span>
                </option>
                <template v-if="healthCentersData.length !== 0">
                  <option
                    v-for="healthCenterData in healthCentersData"
                    :key="healthCenterData.id"
                    :value="healthCenterData.id"
                  >
                    {{ healthCenterData.name }}
                  </option>
                </template>
                <template v-else>
                  <option disabled value="">No towns found</option>
                </template>
              </select>
              <span
                class="text-[0.775rem] text-red-500"
                v-if="form.health_center.id === ''"
                >veuillez entrer votre centre de santé</span
              >
            </SectionComponent>
          </template>

          <!-- vacine for which personType -->
          <SectionComponent>
            <label class="mb-2 text-sm text-gray-700" for="vaccine_person_type">
              Vacine pour:<span v-if="!updateMode" class="text-red-500">*</span>
            </label>
            <template v-if="updateMode">
              <input
                v-model="form.vaccine_person_type.name"
                class="focus:shadow-outline w-full appearance-none px-2 py-1 leading-tight focus:outline-none"
                style="background-color: #e5e7eb"
                id="other_phone"
                type="text"
                disabled
              />
            </template>
            <template v-else>
              <select
                v-model="form.vaccine_person_type.id"
                id="vaccine_person_type"
                @change="onChangeVaccinePersonType"
                class="mt-2 w-full rounded border-2 border-gray-200 px-2 py-1 text-gray-700"
                style="background-color: #e5e7eb"
              >
                <option disabled selected value="">
                  Selectionner une categorie
                </option>
                <template v-if="vaccinePersonTypesData.length !== 0">
                  <option
                    v-for="vaccinePersonTypeData in vaccinePersonTypesData"
                    :key="vaccinePersonTypeData.id"
                    :value="vaccinePersonTypeData.id"
                  >
                    {{ vaccinePersonTypeData.name }}
                  </option>
                </template>
                <template v-else>
                  <option disabled value="">No categories found</option>
                </template>
              </select>
            </template>

            <span
              class="text-[0.775rem] text-red-500"
              v-if="form.vaccine_person_type.id === ''"
              >veuillez entrer votre centre de santé</span
            >
          </SectionComponent>

          <!-- vacine type depends on the vacine for the person type -->
          <template v-if="form.vaccine_person_type.id !== ''">
            <SectionComponent>
              <label class="mb-2 text-sm text-gray-700" for="vaccine_type">
                Choisissez votre visite <span class="text-red-500">*</span>
              </label>
              <select
                v-model="form.vaccine_type.id"
                id="vaccine_type"
                class="mt-2 w-full rounded border-2 border-gray-200 px-2 py-1 text-gray-700"
                style="background-color: #e5e7eb"
              >
                <option disabled selected :value="form.vaccine_type.id">
                  Choisissez votre visite
                </option>
                <template v-if="vaccineTypesData.length !== 0">
                  <option
                    v-for="vaccineTypeData in vaccineTypesData"
                    :key="vaccineTypeData.id"
                    :value="vaccineTypeData.id"
                  >
                    {{ vaccineTypeData.name }}
                  </option>
                </template>
                <template v-else>
                  <option disabled value="">No visites found</option>
                </template>
              </select>
              <span
                class="text-[0.775rem] text-red-500"
                v-if="form.vaccine_type.id === ''"
                >veuillez entrer votre centre de santé</span
              >
            </SectionComponent>
          </template>

          <!-- next visite date -->
          <SectionComponent>
            <label
              class="float-left mb-2 block text-sm text-gray-700"
              for="name"
            >
              Entrez la prochaine date de visite
              <span class="text-red-500">*</span>
            </label>
            <VueDatePicker
              v-model="form.next_visite_date"
              model-type="dd/MM/yyyy"
              placeholder="Cliquez pour choisir la date"
              required
              :format="format"
              :enable-time-picker="false"
            ></VueDatePicker>
            <!-- <p>{{ form.next_visite_date }}</p> -->

            <!-- <input
              v-model="form.next_visite_date"
              class="focus:shadow-outline w-full appearance-none rounded border px-2 py-1 leading-tight focus:outline-none"
              style="background-color: #e5e7eb"
              id="name"
              type="date"
              required
            /> -->
            <span
              class="text-[0.775rem] text-red-500"
              v-if="form.next_visite_date === ''"
              >veuillez renseigner la prochaine date de visite</span
            >
          </SectionComponent>

          <!-- Submit button -->
          <SectionComponent>
            <div class="w-full text-center">
              <button
                v-if="!isFormLoading"
                type="submit"
                :class="[
                  'form-medium w-full rounded px-5 py-2.5 text-sm text-white focus:ring-0',
                  buttonClass,
                ]"
              >
                {{ buttonText }}
              </button>
              <LoaderComponent v-else />
            </div>
          </SectionComponent>
        </form>
      </template>
    </template>
  </template>
</template>
