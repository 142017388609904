<script>
import PatientFormComponent from "@/components/PatientFormComponent.vue";
import vaccineService from "@/services/vacineService";

export default {
  components: { PatientFormComponent },
  name: "UpdatePage",
  data() {
    return {
      isPatientDataLoading: false,
      isPatientError: false,

      patientData: {
        id: "",
        name: "",
        phone: "",
        other_phone: "",
        town: {
          id: "",
          name: "",
        },
        patient_name: "",
        patient_dob: "",
        health_center: {
          id: "",
          name: "",
        },
        district: {
          id: "",
          name: "",
        },
        vaccine_person_type: {
          id: "",
          name: "",
        },
        vaccine_type: {
          id: "",
          name: "",
        },
        next_visite_date: "",
      },
      vaccineTypesForPatientType: [],
    };
  },
  methods: {
    async getUpdatedVaccineTypesList(vaccineId, vaccineTypesArray) {
      const startIndex = await vaccineTypesArray.findIndex(
        (vaccineType) => vaccineType.id == vaccineId
      );

      // If the vaccine_id is not found or it's the last one, return an empty array
      if (startIndex === -1 || startIndex === vaccineTypesArray.length - 1) {
        return [];
      }

      // Return the sublist starting from the next index to the end
      return vaccineTypesArray.slice(startIndex + 1);
    },

    async getAllVaccineByPatientType(patientTypeId, prev_vaccine_type_id) {
      const vaccinesData = await vaccineService.getAllVaccineType();
      const vaccinesForPatientType = vaccinesData.filter((vaccineData) => {
        return vaccineData.vaccine_id == patientTypeId;
      });

      const vaccinesForPatientLeft = await this.getUpdatedVaccineTypesList(
        prev_vaccine_type_id,
        vaccinesForPatientType
      );
      return vaccinesForPatientLeft ?? [];
    },

    async getVaccinePersonType(patientTypeId) {
      const vaccinePersTypesData =
        await vaccineService.getAllVacinesPersonTypes();
      const vaccinePersonTypeData = vaccinePersTypesData.find(
        (vaccinePersTypeData) => {
          // Remove type check here due to id in integer
          return vaccinePersTypeData.id == patientTypeId;
        }
      );
      return (
        vaccinePersonTypeData ?? {
          id: "",
          name: "",
        }
      );
    },
    async getPatientData() {
      try {
        this.isPatientDataLoading = true;
        const patientId = this.$route.params.id;

        const patient = {
          birth: this.$route.query.birth,
          register_name: this.$route.query.register_name,
          patient_name: this.$route.query.patient_name,
          phone: this.$route.query.phone,
          other_phone: this.$route.query.other_phone,
          vaccine_person_type: {
            id: this.$route.query.vaccine_person_type_id,
          },
          prev_vaccine_type: {
            id: this.$route.query.prev_vaccine_type_id,
          },
        };

        // console.log("Patient gotten from url: ", patient);
        const formDataNewConstruct = {
          id: patientId,
          name: patient.register_name,
          phone: patient.phone,
          other_phone: patient.other_phone,
          town: {
            id: "",
            name: "",
          },
          patient_name: patient.patient_name,
          patient_dob: patient.birth,
          health_center: {
            id: "",
            name: "",
          },
          district: {
            id: "",
            name: "",
          },

          vaccine_type: {
            id: "",
            name: "",
          },
          next_visite_date: "",
          vaccine_person_type: {
            id: "",
            name: "",
          },
        };

        const vaccinePersonTypeObj = await this.getVaccinePersonType(
          patient.vaccine_person_type.id
        );
        formDataNewConstruct.vaccine_person_type = vaccinePersonTypeObj;

        const newVaccineList = await this.getAllVaccineByPatientType(
          patient.vaccine_person_type.id,
          patient.prev_vaccine_type.id
        );
        this.patientData = formDataNewConstruct;
        this.vaccineTypesForPatientType = newVaccineList;
      } catch (error) {
        this.isPatientError = true;
      } finally {
        this.isPatientDataLoading = false;
      }
    },
  },

  async mounted() {
    await this.getPatientData();
  },
};
</script>

<template>
  <template v-if="isPatientDataLoading">
    <ContentSectionLoading />
  </template>
  <template v-else>
    <template v-if="isPatientError">
      <div>
        <span>Sorry, patient not found</span>
        <span><router-link to="/register" /></span>
      </div>
    </template>
    <template v-else>
      <PatientFormComponent
        :update-mode="true"
        :patient="patientData"
        :patient-id="patientData.id"
        :vaccine-types-by-patient="vaccineTypesForPatientType"
      />
    </template>
  </template>
</template>
